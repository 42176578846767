/* eslint-disable no-undefined */
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Row } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BiDownload } from 'react-icons/bi';
import { CsvExport } from '../../components/Common/CsvExport';
import StyledButton from '../../components/Common/StyledButton';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledModal from '../../components/Common/StyledModal';
import { StyledPagination } from '../../components/Common/StyledPagination';
import StyledSearchInput from '../../components/Common/StyledSearchInput';
import StyledSelect from '../../components/Common/StyledSelect';
import { UpdateDate } from '../../components/Common/UpdateDate';
import { EditableCell } from '../../components/EditableCell';
import { Loading } from '../../components/Loading';
import { ModalStyled } from '../../components/Modal';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import SimpleTable from '../../components/Tables/SimpleTable';
import { getURI } from '../../helpers/utils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import theme from '../../styles/theme';
import { AVLCDetails } from './Details';
import { NewComponent } from './NewComponent';
import * as S from './styles';
import { useAVLC } from './useAVLC';

export function AVLCPage({ view = null, dataModal = null }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.avlc.title'));

  const {
    data,
    params,
    pageControl,
    fetchAVLC,
    handleChangeTable,
    isLoading,
    mergedColumns,
    onModalClose,
    modalData,
    lastDate,
    transformData,
    getQueryParams,
    headerCsv,
    componentModalData,
    form,
    saveComponent,
    clearAllFilters,
    handleParams,
    projects,
    categories,
    specs,
    partNumbers,
    sources,
    manufacturers,
    status,
    CMs,
    products,
    changePage,
    spec,
    setSpec,
  } = useAVLC(dataModal);

  const modalTitle = useMemo(() => {
    return `${t('pages.avlc.fields.project')}: ${modalData?.projectName}`;
  }, [modalData]);

  const showTagsFilter =
    params.projectName?.length ||
    params.partType?.length ||
    params.spec?.length ||
    params.partNumber ||
    params.qStatus ||
    params.location ||
    params.vendor;

  return (
    <main>
      {isLoading ? (
        <Loading />
      ) : (
        <Col span={24} className="filter-page">
          <Row justify="center">
            <Col span={24}>
              <StyledHeader
                title={t('pages.avlc.title')}
                items={pageControl.totalElements > 0 ? pageControl.totalElements : undefined}
                actions={
                  <StyledButton variant="primary" key={2} icon={<BiDownload />}>
                    <CsvExport
                      url={getURI(`avlc`, { ...getQueryParams(params), generateTable: true })}
                      filename={['avlc', new Date().toDateString()]}
                      limit={pageControl.totalElements}
                      headers={headerCsv}
                      transformData={transformData}
                    />
                  </StyledButton>
                }
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <S.ContainerFilters>
                <StyledSelect
                  onChange={(e: string) => handleParams('cm', e)}
                  styles={{ minWidth: '8.5rem' }}
                  showSearch
                  options={CMs}
                  value={params.cm}
                  label={t('pages.avlc.fields.cm')}
                />
                <StyledSelect
                  onChange={(e: string) => handleParams('projectName', e)}
                  styles={{ minWidth: '8.5rem' }}
                  showSearch
                  options={projects}
                  value={params.projectName}
                  label={t('pages.avlc.filters.project')}
                  placeholder={t('pages.avlc.filters.projectPlaceholder')}
                />
                <StyledSelect
                  onChange={(e: string) => handleParams('partType', e)}
                  styles={{ minWidth: '8.125rem' }}
                  showSearch
                  options={categories}
                  value={params.partType}
                  label={t('pages.avlc.filters.category')}
                  placeholder={t('pages.avlc.filters.categoryPlaceholder')}
                />
                <StyledSearchInput
                  placeholder={t('pages.avlc.filters.specificationPlaceholder')}
                  label={`${t('pages.avlc.filters.specification')}`}
                  value={spec}
                  onChange={(event) => {
                    setSpec(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleParams('spec', spec);
                      setSpec('');
                    }
                  }}
                  suffix={
                    <S.SearchButton
                      onClick={() => {
                        handleParams('spec', spec);
                        setSpec('');
                      }}
                    >
                      <SearchOutlined className="icon" />
                    </S.SearchButton>
                  }
                />
                <StyledSelect
                  onChange={(e: string) => handleParams('partNumber', e)}
                  styles={{ minWidth: '8rem' }}
                  showSearch
                  options={partNumbers}
                  value={params.partNumber}
                  label={t('pages.avlc.filters.partNumber')}
                  placeholder={t('pages.avlc.filters.partNumberPlaceholder')}
                />
                <StyledSelect
                  onChange={(e: string) => handleParams('location', e)}
                  styles={{ minWidth: '6.25rem' }}
                  options={sources}
                  value={params.location}
                  label={t('pages.avlc.filters.source')}
                  placeholder={t('pages.avlc.filters.sourcePlaceholder')}
                />
                <StyledSelect
                  onChange={(e: string) => handleParams('vendor', e)}
                  styles={{ minWidth: '8.5rem' }}
                  showSearch
                  options={manufacturers}
                  value={params.vendor}
                  label={t('pages.avlc.filters.manufacturer')}
                  placeholder={t('pages.avlc.filters.manufacturerPlaceholder')}
                />
                <StyledSelect
                  onChange={(e: string) => handleParams('qStatus', e)}
                  styles={{ minWidth: '6.625rem' }}
                  label={t('pages.avlc.filters.status')}
                  options={status}
                  value={params.qStatus}
                  placeholder={t('pages.avlc.filters.statusPlaceholder')}
                />
                <Checkbox
                  style={{ padding: '0.375rem' }}
                  checked={params.hasComponent || false}
                  onChange={() => {
                    const newValue = params.hasComponent ? null : true;
                    handleParams('hasComponent', newValue);
                  }}
                >
                  {t('pages.avlc.filters.noRegistryComponent')}
                </Checkbox>
                <StyledButton
                  onClick={() => {
                    handleParams('page', 0);
                  }}
                  variant="primary"
                  text={t('common.apply')}
                />
              </S.ContainerFilters>
            </Col>
            <Col span={24}>
              <S.FiltersTagContainer>
                {params.projectName && (
                  <StyledFilterTag
                    label={t('pages.avlc.filters.project')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.projectName}
                    closeble
                    onClose={() => handleParams('projectName', '')}
                  />
                )}
                {params.partType && (
                  <StyledFilterTag
                    label={t('pages.avlc.filters.category')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.partType}
                    closeble
                    onClose={() => handleParams('partType', '')}
                  />
                )}
                {params.spec && (
                  <StyledFilterTag
                    label={t('pages.avlc.filters.specification')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.spec}
                    closeble
                    onClose={() => handleParams('spec', '')}
                  />
                )}
                {params.partNumber && (
                  <StyledFilterTag
                    label={t('pages.avlc.filters.partNumber')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.partNumber}
                    closeble
                    onClose={() => handleParams('partNumber', '')}
                  />
                )}
                {params.location && (
                  <StyledFilterTag
                    label={t('pages.avlc.filters.source')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.location}
                    closeble
                    onClose={() => handleParams('location', '')}
                  />
                )}
                {params.vendor && (
                  <StyledFilterTag
                    label={t('pages.avlc.filters.manufacturer')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.vendor}
                    closeble
                    onClose={() => handleParams('vendor', '')}
                  />
                )}
                {params.qStatus && (
                  <StyledFilterTag
                    label={t('pages.avlc.filters.status')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.qStatus}
                    closeble
                    onClose={() => handleParams('qStatus', '')}
                  />
                )}
                {showTagsFilter && <StyledClearTag onClick={clearAllFilters} />}
              </S.FiltersTagContainer>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <UpdateDate date={lastDate} />
              <SimpleTable
                rowKey="id"
                data-cy="table"
                striped
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                scroll={{ x: 'max-content', y: 'calc(100vh - 24rem)' }}
                columns={mergedColumns}
                dataSource={data}
                onChange={handleChangeTable}
                pagination={false}
                expandRowByClick={false}
                locale={{
                  emptyText: (
                    <ResultNoScenarios
                      title={t('common.results.noDataTitle')}
                      subTitle={t('common.results.noDataSubtitle')}
                      icon={null}
                    />
                  ),
                }}
              />

              <ModalStyled
                key="modal-avlc"
                title={modalTitle}
                open={modalData !== null}
                onCancel={onModalClose}
                onOk={onModalClose}
                width={1200}
                footer={null}
              >
                <AVLCDetails data={modalData} />
              </ModalStyled>

              <StyledModal
                key="modal-newcomponent"
                title={t('pages.avlc.fields.modalName')}
                open={componentModalData !== null}
                onClose={onModalClose}
                footer={
                  <Row justify="space-between">
                    <Button type="link" onClick={onModalClose}>
                      {t('common.cancel')}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        form.validateFields().then(() => {
                          saveComponent();
                        });
                      }}
                    >
                      {t('common.save')}
                    </Button>
                  </Row>
                }
                body={<NewComponent form={form} cmOptions={CMs} productOptions={products} />}
                width="64rem"
              />

              {pageControl.totalPages > 1 && (
                <StyledPagination
                  size={params.limit}
                  totalElements={pageControl.totalElements}
                  pageNumber={params.page + 1}
                  onChange={changePage}
                />
              )}
            </Col>
          </Row>
        </Col>
      )}
    </main>
  );
}
